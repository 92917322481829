<template>
  <v-container fluid>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 100vh; position: relative"
    >
      <div class="overflow-y">
        <table width="100%" class="detail-table table-head-sticky">
          <thead>
            <tr class="custom-border">
              <th class="simple-table-th border-right-new"></th>
              <th class="simple-table-th border-right-new">Related To</th>
              <th class="simple-table-th border-right-new">Name</th>
              <th class="simple-table-th border-right-new">Added At</th>
              <th class="simple-table-th"></th>
              <th class="simple-table-th"></th>
            </tr>
          </thead>

          <tbody v-if="itemImages.length">
            <tr
              v-for="(row, index) in itemImages"
              :key="index"
              class="custom-border"
            >
              <td class="px-2 py-1 border-top-light-grey border-right-new">
                <template v-if="row.newextention && isImage(row.newextention)">
                  <ImageTemplate
                    :src="row.file.url"
                    style="max-width: 50px; width: 50px"
                  ></ImageTemplate>
                </template>
                <template v-else>
                  <inline-svg
                    style="max-width: 50px; width: 50px"
                    :src="$assetURL(`media/mime/${row.newextention}.svg`)"
                  />
                </template>
              </td>
              <td class="px-2 py-1 border-top-light-grey border-right-new">
                <p class="m-0 blue--text font-level-1 cursor-pointer bold-600">
                  <v-chip
                    :color="getColor(row.type)"
                    label
                    class="text-white"
                    outlined
                    style="height: 25px"
                  >
                    {{ getType(row.type) }}
                  </v-chip>
                </p>
                <div
                  v-if="
                    row &&
                    row.type == 90 &&
                    row.milestoneContact &&
                    row.milestoneContact.barcode
                  "
                >
                  <Barcode :barcode="row.milestoneContact.barcode"></Barcode>
                </div>
              </td>
              <td class="px-2 py-1 border-top-light-grey border-right-new">
                <p
                  class="m-0 blue--text font-level-1 cursor-pointer bold-600"
                  v-on:click="doAction(row, 'download')"
                >
                  {{ row.name }}
                </p>
              </td>

              <td
                class="px-2 py-1 border-top-light-grey font-level-1 border-right-new"
              >
                <div class="d-flex justify-space-between">
                  <v-chip
                    color="cyan"
                    label
                    class="text-white"
                    outlined
                    style="height: 25px"
                  >
                    {{ formatDate(row.added_at) }}
                  </v-chip>
                </div>
              </td>
              <td class="px-2 py-1 border-top-light-grey font-level-1">
                <div class="d-flex justify-space-between">
                  <template v-if="row && row.added_by && false">
                    <Barcode :barcode="row.added_by.display_name"></Barcode>
                  </template>
                  <!--  <div md1>{{ getFileSize(row.size) }} KB</div> -->
                </div>
              </td>
              <td class="px-2 py-1 border-top-light-grey font-level-1">
                <v-btn
                  v-on:click="doAction(row, 'download')"
                  icon
                  depressed
                  color="blue darken-4"
                  class="mr-2"
                  ><v-icon medium>mdi-download</v-icon></v-btn
                >
                <!--  <v-btn
                v-on:click="doAction(row, 'delete')"
                icon
                depressed
                color="red lighten-1"
                ><v-icon medium>mdi-delete</v-icon></v-btn
              > -->
              </td>
            </tr>
          </tbody>
          <tfoot v-else>
            <tr>
              <td colspan="6">
                <p class="m-0 row-not-found text-center">
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no file at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { PATCH, DELETE } from "@/core/services/store/request.module";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ImageTemplate from "@/view/pages/Image";
import { toNumber, round, find } from "lodash";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  mixins: [FileManagerMixin, CommonMixin],
  props: {
    type: {
      type: String,
      required: true,
      default: "addjustment",
    },
    parent: {
      type: Number,
      required: true,
      default: 0,
    },
    isPageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    images: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      selectedImageextension: null,
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
      primaryLoading: false,
      deleteLoading: false,
      isPrimary: false,
      selectedImage: null,
      selectedImageId: 0,
      itemImages: new Array(),
    };
  },
  components: {
    ImageTemplate,
    Barcode,
  },
  watch: {
    images: {
      deep: true,
      immediate: true,
      handler(param) {
        this.itemImages = param;
        this.setFirstImage();
      },
    },
  },
  mounted() {
    this.setFirstImage();
  },
  methods: {
    getType(type) {
      if (type == 111) {
        return "Project";
      } else if (type == 112) {
        return "Bank";
      } else if (type == 113) {
        return "Insurance";
      } else if (type == 90) {
        return "Milestone";
      } else if (type == 114) {
        return "Quotation";
      }
    },
    getColor(type) {
      if (type == 111) {
        return "cyan";
      } else if (type == 112) {
        return "blue";
      } else if (type == 113) {
        return "green";
      } else if (type == 90) {
        return "orange";
      } else if (type == 114) {
        return "red";
      }
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url);
    },
    getFileSize(size) {
      return round(toNumber(size) / 1024, 2);
    },
    doAction(row, param) {
      if (param == "download") {
        window.open(row.file.url, "_blank");
      }
    },
    deleteImage() {
      const _this = this;
      _this.pageLoading = true;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: `file-manager/delete/${_this.selectedImageId}`,
        })
        .then(() => {
          let index = _this.lodash.findIndex(_this.itemImages, function (row) {
            return row.id == _this.selectedImageId;
          });
          if (index >= 0) {
            _this.itemImages.splice(index, 1);
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.deleteLoading = false;
        });
    },
    setAsPrimary() {
      const _this = this;
      _this.pageLoading = true;
      _this.primaryLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `${_this.type}/${_this.parent}/image/${_this.selectedImageId}/primary`,
        })
        .then(({ data }) => {
          _this.itemImages = data;
          _this.setFirstImage();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.primaryLoading = false;
        });
    },
    setFirstImage() {
      const _this = this;
      let firstImage = _this.lodash.head(_this.itemImages);
      if (_this.lodash.isEmpty(firstImage) === false) {
        _this.selectedImage = firstImage.file.url;
        _this.selectedImageextension = firstImage.file.name;

        _this.selectedImageId = firstImage.id;
        _this.isPrimary = firstImage.primary;
      }
    },

    isImageextension(ext) {
      let extension = ext.split(".");
      this.extensionname = extension[1];
      let data = find(
        this.extensionArray,
        (row) => row == extension.at(-1).toLowerCase()
      );
      console.log({ data });
      if (
        find(
          this.extensionArray,
          (row) => row == extension.at(-1).toLowerCase()
        )
      ) {
        //console.log(extension[1], "----" + index);
        return true;
      } else {
        // console.log(extension[1], "||||||" + index);
        return false;
      }
    },

    getExtension(ext) {
      let extension = ext.split(".");
      return extension.at(-1);
    },

    isImageextensionsecond(ext, index) {
      let extension = ext.split(".");
      this.extensionname = extension[1];
      let data = find(
        this.extensionArray,
        (row) => row == extension.at(-1).toLowerCase()
      );
      console.log({ data });
      if (
        find(
          this.extensionArray,
          (row) => row == extension.at(-1).toLowerCase()
        )
      ) {
        console.log(extension[1], "----" + index);
        return true;
      } else {
        console.log(extension[1], "||||||" + index);
        return false;
      }
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    setImage(row) {
      this.selectedImage = row.file.url;
      this.selectedImageId = row.id;
      this.isPrimary = row.primary;
    },
  },
};
</script>
<style scoped>
.detail-table.table-head-sticky thead tr.custom-border th {
  background-color: #f3f6f9;
  padding: 4px;
}
.detail-table.table-head-sticky tbody tr.custom-border:nth-child(odd) {
  background-color: #fff4de !important;
}
.detail-table.table-head-sticky tbody tr.custom-border:hover {
  background-color: #fff4de !important;
}
</style>
